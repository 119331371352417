// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_loader__DwtSp {\n  max-width: 300px;\n  min-height: 300px;\n  align-self: center;\n  text-align: center;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  margin: 0 auto;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 24px;\n  color: var(--primary-text-color);\n}", "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EAEA,kCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EAEA,gCAAA;AADJ","sourcesContent":[".loader {\n    max-width: 300px;\n    min-height: 300px;\n    align-self: center;\n    text-align: center;\n    justify-content: center;\n    display: flex;\n    align-items: center;\n    margin: 0 auto;\n\n    font-family: Open Sans, sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 24px;\n\n    color: var(--primary-text-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "Loader_loader__DwtSp"
};
export default ___CSS_LOADER_EXPORT___;
