import { useState, useEffect } from 'react';
import { voleeApi } from 'api/VoleeApi';
import { useCommunity } from 'context/community';
import { useCurrentUser } from 'context/community';

const UPDATE_TIMEOUT = 15000;

const useChats = () => {
    const [chats, setChats] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const { community: { id: communityId } } = useCommunity();
    const { connectedParticipants } = useCurrentUser();

    const fetchChats = async () => {
        try {
            const response = await voleeApi.chats.get(communityId);
            setChats(response);
        } catch (error) {
            console.error('Error fetching chats:', error);
        }
    };

    const fetchUnreadCount = async () => {
        try {
            const response = await voleeApi.chats.unread()
            setUnreadCount(response);
        } catch (error) {
            console.error('Error fetching unread count:', error);
        }
    };

    useEffect(() => {
        fetchUnreadCount();
        const interval = setInterval(fetchUnreadCount, UPDATE_TIMEOUT);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (communityId && connectedParticipants?.length) {
            fetchChats();
        }
    }, [communityId, connectedParticipants]);

    return { chats, unreadCount };
};

export default useChats;
